import React, { createContext, useEffect, useState } from 'react';
import config from '../config';
import { useParams } from 'react-router-dom';
import useAlert from '../hooks/useAlert';

export const GroupContext = createContext();

export const GroupProvider = ({ children }) => {

	const { groupId, shiftId } = useParams();
	const [group, setGroup] = useState(null);
	const [shift, setShift] = useState(null);
	const { alertError, alertSuccess } = useAlert();

	async function fetchShift(shiftId) {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/shifts/${shiftId}`, {
			credentials: 'include',
		});
		if (response.ok)
		{
			const data = await response.json();
			if (data.group === group._id)
				setShift(data);
		}
		else if (response.status === 401 && window.location.pathname !== '/login') {
			window.location.href = '/login';
		}
		else {
			alertError(`Failed to fetch shift ${shiftId}`);
			setShift(undefined);
		}
	}

	async function fetchGroup(groupId) {
		const response = await fetch(`${config.apiUrl}/api/groups/${groupId}`, {
			credentials: 'include',
		});
		if (response.ok) {
			const data = await response.json();
			setGroup(data);
			localStorage.setItem('group', data.name);
		}
		else if (response.status === 401 && window.location.pathname !== '/login') {
			window.location.href = '/login';
		}
		else
			alertError(`Failed to fetch group ${groupId}`);
	}

	useEffect(() => {
		const localShift = localStorage.getItem('shift');
		if (!shiftId && group && localShift)
			fetchShift(localShift);
		// eslint-disable-next-line
	}, [shiftId, group])

	useEffect(() => {
		const localGroup = localStorage.getItem('group');
		if (groupId && !group)
			fetchGroup(groupId)
		else if (localGroup && !group)
			fetchGroup(localGroup)
		// eslint-disable-next-line
	}, [groupId]);


	useEffect(() => {
		if (group && shiftId && !shift)
			fetchShift(shiftId)
		// eslint-disable-next-line
	}, [shiftId, group]);

	const setDiscordChannel = async (channelId) => {
		const response = await fetch(`${config.apiUrl}/api/groups/${group.name}/discordChannel`, {
			method: 'PUT',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({channelID: channelId.length > 0 ? channelId : null})
		});
		if (response.ok)
		{
			setGroup({...group, discordChannelID: channelId});
			alertSuccess('Discord Channel ID updated');
			return true;
		}
		else
			alertError('Failed to update Discord Channel ID');
		return false;
	}

	return (
		<GroupContext.Provider value={{ group, shift, setDiscordChannel }}>
			{children}
		</GroupContext.Provider>
	);
};